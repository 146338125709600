import { FC } from 'react';
import { INewCollectionList } from '@modules/collections/domain/models/newCollection.model';
import { RecipeThumb } from '@components/recipe/thumbnail';
import { useScreen } from '@shared/helpers/screen.helper';
import { useWindowSize } from '@shared/utils/hooks';
import { analytics } from '@shared/utils/segment.utils';
import { CollectionCover } from '.';
import breakpoint from '../../../../shared/assets/breakpoint.json';
import { Container, LeftContent, MobileRecipeThumbWrapper, RightContent } from './NewCollectionLayout.style';
interface Props {
  data: INewCollectionList;
}
const NewCollectionLayout: FC<Props> = ({
  data
}: Props) => {
  const {
    width
  } = useWindowSize();
  const {
    isDesktop
  } = useScreen();
  const {
    name,
    menuAmount,
    imageUrl,
    videoUrl,
    slug,
    recipeList,
    description
  } = data;
  const calRecipeThumbWidth = (): string => {
    if (width < breakpoint.sm - 1) {
      return '166px';
    } else if (breakpoint.sm - 1 < width && width < breakpoint.md - 1) {
      return '285px';
    } else {
      return '340px';
    }
  };
  const handlerRecipeEventTacking = (title: string) => {
    analytics.track('Recipe Viewed', {
      event: 'Recipe Viewed',
      recipe_name: title,
      ingredient: ''
    });
  };
  const renderCollection = () => {
    if (isDesktop) {
      return <Container>
                    <LeftContent>
                        <CollectionCover menuAmount={menuAmount} name={name} imageUrl={imageUrl} videoUrl={videoUrl} slug={slug} recipeList={recipeList.thumbS} description={description} />
                    </LeftContent>
                    <RightContent>
                        {recipeList.thumbM?.map((recipe, index) => <div key={index} onClick={() => analytics.track('Collection Thumbnail M Click', {
            event: 'Collection Thumbnail M Click'
          })}>
                                <RecipeThumb imageUrl={recipe.imageUrl} name={recipe.name} viewer={recipe.viewer} slug={recipe.slug} onClickRecipeEventTacking={() => handlerRecipeEventTacking(recipe.name)} />
                            </div>)}
                    </RightContent>
                </Container>;
    }
    return <div>
                <CollectionCover menuAmount={menuAmount} name={name} imageUrl={imageUrl} videoUrl={videoUrl} slug={slug} recipeList={recipeList.thumbS} description={description} />
                <MobileRecipeThumbWrapper>
                    {recipeList.thumbM?.map((recipe, index) => <RecipeThumb key={index} imageUrl={recipe.imageUrl} name={recipe.name} viewer={recipe.viewer} slug={recipe.slug} width={calRecipeThumbWidth()} />)}
                </MobileRecipeThumbWrapper>
            </div>;
  };
  return renderCollection();
};
export default NewCollectionLayout;